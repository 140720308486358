import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {
    var splide = new Splide('.splide-war', {
        type: 'loop',
        focus: 'left',
        drag: 'free',
        autoWidth: true,
        pagination: false,
        arrows: false,
    });

    splide.mount();
});
