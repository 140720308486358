import Splide from '@splidejs/splide';
import generateHeroSlider from './generateHeroSlider';

document.addEventListener('DOMContentLoaded', function () {
  generateHeroSlider(null, 'all', false, 650)
  generateHeroSlider(null, 'news', false, 650),
  generateHeroSlider(null, 'articles', false, 650)
  
  const main = new Splide('.category-tabs-slider', {
    type: 'fade',
    gap: 0,
    pagination: false,
    arrows: false,
    drag: false,
  });

  const thumbnails = new Splide('.tabs-category-slider', {
    gap: 10,
    rewind: true,
    pagination: false,
    autoWidth: true,
    drag: false,
    arrows: false,
    isNavigation: true,
    i18n: {
      slideX: '',
    },
  });

  main.sync(thumbnails);
  main.mount();
  thumbnails.mount();

});