import Splide from '@splidejs/splide';

function generateHeroSlider(e, sectionName = 'hero-section', mdAutoHeight = false, customMdHeight) {
    const thumbnails = new Splide(`.${sectionName}__thumbnails`, {
        type: 'loop',
        fixedWidth: 304,
        fixedHeight: 80,
        isNavigation: true,
        gap: 32,
        focus: 'left',
        pagination: false,
        i18n: {
          slideX: '',
        },
        perMove: 1,
        perPage: 1,
        dragMinThreshold: {
            mouse: 0,
            touch: 0,
        },
        breakpoints: {
            1024: {
                gap: 16,
            },
        },
    });

    const main = new Splide(`.${sectionName}__main-slider`, {
        type: 'fade',
        rewind: 'true',
        pagination: false,
        arrows: false,
        fixedHeight: '654px',
        breakpoints: {
            1024: {
                fixedHeight: mdAutoHeight || '654px'
            },
            768: {
                fixedHeight: mdAutoHeight || customMdHeight || '523px',
            },
        },
    });

    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();

    const slideNumbers = Array.from(document.querySelectorAll(`.${sectionName}__thumbnails .splide__list .splide__slide`))
        .filter(itm => !itm.classList.contains('splide__slide--clone'));

    const slidesMaxCountEl = document.querySelector(`.${sectionName}__main-slider .splide__arrows-count .splide__arrows-count-s`);
    const slidesCurrentCountEl = document.querySelector(`.${sectionName}__main-slider .splide__arrows-count .splide__arrows-count-f`);
    slidesMaxCountEl.innerHTML = slideNumbers.length;
    main.on('active', (e) => {
        slidesCurrentCountEl.innerHTML = e.index + 1;
        if (e.index === (slideNumbers.length - 1)) {
            slidesMaxCountEl.style.opacity = 1;
        } else {
            slidesMaxCountEl.style.opacity = 0.5;
        }
    });

    return {main, thumbnails}

}

export default generateHeroSlider;