// import getSidebarItem from './load-more-elements/sidebar-item';

function generateTabs(sectionName, {
    isLoadMore = false,
    hasCaching = true,
    display = 'block',
    initialTab = localStorage.getItem('sidebar-all-news-active-tab') || 'all'
} = {}) {
    const tabsWrapper = document.querySelector(`.tabs-${sectionName}`);
    const tabs = tabsWrapper?.children;
    if (!tabs) {
        return
    }
    const sidebarInner = isLoadMore && document.querySelector(`.${sectionName} .sidebar__inner`);
    const tabsContentList = document.querySelectorAll(`.${sectionName}-tab-content`);
    // const loadMoreBtn = isLoadMore && sidebarInner.querySelector(`button`);
    let selectedTabContent = {
        tabContentName: initialTab,
        tabContentEl: document.querySelector(`.${sectionName}-tab-content[data-tabData='${initialTab}']`)
    };
    if (hasCaching) {
        let tabElement = tabsWrapper.querySelector(`.tabs__item[data-tab='${initialTab}']`);
        if (tabElement) {
            openTab(tabElement,
                tabsContentList,
                document.querySelector(`.${sectionName}-tab-content[data-tabData='${initialTab}']`),
                selectedTabContent);
        }
    }
    Array.from(tabs).forEach((itm) => {
        itm.onclick = (e) => {
            const tabEl = e.target;
            const tabContent = document.querySelector(`.${sectionName}-tab-content[data-tabData='${tabEl.dataset.tab}']`);
            localStorage.setItem('sidebar-all-news-active-tab', tabEl.dataset.tab);
            openTab(tabEl, tabsContentList, tabContent, selectedTabContent);
        };
    });

    function openTab(tabEl, tabsContentList, tabContent, selectedTabContent) {
        Array.from(tabsContentList).forEach((itm, i) => {
            itm.style.display = "none";
            tabs[i].classList.remove('tabs__item_active')
        });
        tabEl.classList.toggle('tabs__item_active');
        tabContent.style.display = display;
        selectedTabContent.tabContentName = tabEl.dataset.tab;
        selectedTabContent.tabContentEl = tabContent;
    }

}

export default generateTabs;
